<template>
  <div>
    <div class="total">共有{{totalCount}}条数据</div>
    <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
          v-if="dataList.length > 0" offset="10">
        <div class="items" v-for="item in dataList" :key="item.id" @click="goInfo(item.id)">
          <img :src="item.img || require('@/assets/img/newVersion/shanshui.png')" alt="" class="left">
          <div class="mid">
            <div class="mid-top">
              <div class="text">违停时间：</div>
              <div class="time">{{item.mistakeTime}}</div>
            </div>
            <div class="mid-down">
              <div class="text">违停描述：</div>
              <span class="des">{{item.content.length > 45 ? item.content.slice(0,45) + '...' : item.content}}</span>
            </div>
          </div>
          <img :src="require('@/assets/img/newVersion/right.png')" class="right" alt="">
        </div>
      </van-list>
      <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
    </van-pull-refresh>
  </div>
</template>

<script>
import {getImageStream} from '@/utils/index'
export default {
  data() {
    return {
      id: '',
      page: 0,
      limit: 10,
      totalCount: 0,
      finished: false,
      loading: false,
      pullLoading: false,
      dataList:[],
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.page++
      this.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true, });
      this.$http({
        url: this.$http.adornUrl('/wxapp/device/car/mistake/list'),
        method: 'GET',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          carId: this.id,
        })
      }).then(({data}) => {
        this.$toast.clear()
        if(data && data.code === 0) {
          this.pullLoading = false
          if (this.dataList.length == data.page.totalCount) {
              this.finished = true
          }
          this.totalCount = data.page.totalCount
          this.dataList = this.dataList.concat(data.page.list.map(item => {
            return {
              ...item,
              img:item.img ? getImageStream(item.img) : ''
            }
          }))
          this.loading = false
        }else {
          this.$toast.fail(data.msg)
        }
      })
    },
    onPullDownRefresh() {
      this.finished = false;
      this.dataList = [];
      this.page = 0;
      this.getDataList();
    },
    goInfo(id) {
      this.$router.push({path: '/illegallyInfo',query: {id}})
    }
  }
}
</script>

<style lang="scss" scoped>
.total {
  width: 100%;
  height: 58px;
  font-size: 24px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #999999;
  line-height: 58px;
  text-align: center;
}
.items {
  width: 100%;
  height: 208px;
  background: #FFFFFF;
  padding: 24px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  .left {
    width: 160px;
    height: 160px;
    margin-right: 16px;
  }
  .mid {
    height: 100%;
    font-size: 28px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: #999999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 48px;
    .mid-top {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .time {
        color: #E9564B;
      }
    }
    .mid-down {
      margin-block: 10px;
      .text {
        display: inline;
      }
      .des {
        color: #333333;
      }
    }
  }
  .right {
    // width: 28px;
    height: 28px;
  }
}
</style>